import { Injectable } from '@jack-henry/frontend-utils/di';
import { CompaniesClient } from '@treasury/api/bo';
import CompanyRequests from '../../requests/companies/company-requests';
import { Company } from './company.entity';

export enum CompanySearchField {
    CompanyName = 'Company+Name',
    CompanyId = 'Company+Id',
    CompanyTaxNumber = 'TAX+ID+Number',
    CompanyCifNumber = 'CIF+Number',
}

@Injectable()
export class CompanySearchService {
    constructor(private readonly companiesClient: CompaniesClient) {}

    /**
     * Searches for companies by company name.
     */
    public async getCompanies() {
        const resp = await this.companiesClient.companiesGet({
            searchType: CompanySearchField.CompanyName,
            searchValue: '',
        });

        return resp.data.map(c => new Company(c));
    }

    /**
     * Searches for companies that are not onboarded.
     */
    public async alternateSearch(field: CompanySearchField, searchTerm: string) {
        const resp = await this.companiesClient.companiesNonOnboardedCompanies({
            searchType: field,
            searchValue: searchTerm,
        });

        return resp.data.map(c => new Company(c));
    }

    public static async fetchCompanies() {
        return CompanyRequests.fetchCompanies();
    }

    public static async fetchNotOnboardedCompanies(parameters: any) {
        return CompanyRequests.fetchNotOnboardedCompanies(parameters);
    }
}

export default CompanySearchService;
