/* eslint-disable no-use-before-define */
import { DiContainer, InjectionToken } from '@jack-henry/frontend-utils/di';
import { ConfigurationService, Environment } from '@treasury/core/config';
import { TmHttpClient } from '@treasury/core/http';
import { LoggingService } from '@treasury/core/logging';
import { LegacyNavigationService, NavigationService } from '@treasury/core/navigation';
import { NullRealtimeService, RealtimeService } from '@treasury/core/real-time';
import { XperienceService } from '@treasury/core/xperience';
import { AuthenticationService } from '@treasury/domain/services/authentication';
import { Feature, FeatureFlagService } from '@treasury/domain/services/feature-flags';
import {
    LogoutManager,
    OpenIdleDialogToken,
    OpenTimeAccessDialogToken,
} from '@treasury/domain/services/logout';
import { OpenIdentityDialogToken, TmHttpClientMfa } from '@treasury/domain/shared';
import { openIdentityDialog } from '@treasury/omega/domain-components/identity-verification-dialog';
import { openIdleDialog } from '@treasury/omega/domain-components/idle-logout-dialog';
import { openTimeAccessDialog } from '@treasury/omega/domain-components/time-access-logout-dialog';
import { OmegaDialogService } from '@treasury/omega/services';
import { getAngularInjector } from '@treasury/utils';
import { SessionStorageService, WindowService } from '@treasury/utils/services';
import { BoPagePushService } from '../services/common/pagePushSrvc';

export const boDiModule = angular
    .module('backoffice.di', [])
    .constant('TmDi', new DiContainer())
    .config([
        'TmDi',
        (di: DiContainer) => {
            initDi(di);
        },
    ])
    .factory('tmNavService', ['TmDi', (di: DiContainer) => di.get(NavigationService)]);

async function initDi(di: DiContainer) {
    di.provide(OpenIdentityDialogToken as unknown as InjectionToken<unknown>, openIdentityDialog);
    di.provide(OpenIdleDialogToken as unknown as InjectionToken<unknown>, openIdleDialog);
    di.provide(
        OpenTimeAccessDialogToken as unknown as InjectionToken<unknown>,
        openTimeAccessDialog
    );
    di.provide(TmHttpClient, TmHttpClientMfa);
    di.provide(NavigationService, LegacyNavigationService);
    di.provideFactoryAsync(
        RealtimeService,
        [
            ConfigurationService,
            WindowService,
            AuthenticationService,
            LoggingService,
            FeatureFlagService,
        ] as const,
        async (config, window, auth, loggingService, ffService) => {
            await auth.authenticated$.toPromise();

            const { environment } = config;
            const realtimeEnabled =
                environment !== Environment.Local && environment !== Environment.Unknown;
            const realtimeService = realtimeEnabled
                ? new RealtimeService(config, window)
                : NullRealtimeService;

            try {
                const useMainHub = await ffService.isEnabled(Feature.ChannelPushNotifications);
                const hubName = useMainHub ? 'pushNotificationHub' : 'notificationHub';

                realtimeService.init(hubName, environment !== Environment.Production);
                await realtimeService.connect();
                loggingService.logInfo('SignalR connection established');
            } catch (e) {
                loggingService.logError('Failed connecting to SignalR', e as Error);
            }

            return realtimeService;
        },
        NullRealtimeService
    );

    di.provideFactoryAsync(
        FeatureFlagService,
        [TmHttpClient, SessionStorageService, ConfigurationService, AuthenticationService] as const,
        async (http, storage, config, auth) => {
            await auth.authenticated$.toPromise();

            const ffService = new FeatureFlagService(http, storage, config);
            await initFfService(ffService);

            return ffService;
        }
    );

    await DiContainer.init(di);

    const authService = di.get(AuthenticationService);
    const http = di.get(TmHttpClient);
    const logoutManager = di.get(LogoutManager);
    const loggingService = di.get(LoggingService);

    loggingService.init();
    http.sessionExpired$.subscribe(() => onSessionEnd(di));
    http.error$.subscribe(err => {
        loggingService.logError('Fetch error', err);
    });
    authService.authenticated$.subscribe(() => onAuthenticated(logoutManager));
    logoutManager.logout$.subscribe(() => onSessionEnd(di));
}

async function onAuthenticated(logoutManager: LogoutManager) {
    // certain endpoint calls must be deferred until after login
    logoutManager.startLogoutTimers();
    const di = await DiContainer.getInstance();
    const ffService = await di.getAsync(FeatureFlagService);
    initFfService(ffService);
}

async function onSessionEnd(di: DiContainer) {
    const ffService = await di.getAsync(FeatureFlagService);
    ffService.reset();

    const dialogService = di.get(OmegaDialogService);
    dialogService.closeAll();

    const realtimeService = di.get(RealtimeService);
    realtimeService.disconnect();

    const navService = di.get(NavigationService);
    const xperienceService = di.get(XperienceService);

    if (xperienceService.isXperienceFrameworkHosted) {
        navService.navigate('xperience/login');
    } else {
        navService.navigate('login');
    }
}

async function initFfService(ffService: FeatureFlagService) {
    const ngInjector = await getAngularInjector();
    const ppService: BoPagePushService = ngInjector.get('pagePushService');
    const user = ppService.getUser();

    if (!user) {
        throw new Error(
            'Could not instantiate FeatureFlagService. Unable to get the current user.'
        );
    }

    const { fiId, loginId } = user;

    await ffService.init(fiId, {
        isAdmin: true,
        isSuperUser: true,
        userId: loginId,
    });
}
