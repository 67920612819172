/* eslint-disable lit/no-native-attributes */
import { InjectProperty, Injectable } from '@jack-henry/frontend-utils/di';
import { Record as FdlRecord, Recordset, RecordsetEvent } from '@treasury/FDL';
import { RecordsetSummaryModel } from '@treasury/FDL/recordset/recordset-summary-model.js';
import { coerceToAsyncValue, exists } from '@treasury/utils';
import { WindowService } from '@treasury/utils/services';
import { Action } from '@treasury/utils/types';
import { TemplateResult, css, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import '../../components/omega-action-bar.js';
import '../../components/omega-alert.js';
import '../../components/omega-button-bar.js';
import '../../components/omega-button.js';
import '../../components/omega-download-bar.js';
import '../../components/omega-field.js';
import '../../components/omega-flyout.js';
import '../../components/omega-summary-tile-select-bar';
import '../../components/omega-table.js';
import '../../components/progress/omega-progress.js';
import { OmegaColumnDefinition, OmegaColumnType } from '../../components/table';
import '../../components/table/table-filters.js';
import TableFilters from '../../components/table/table.filters';
import buildFilter from '../../models/build-filter.js';
import {
    LocalFilter,
    LocalFilterResult,
    OmegaReportAction,
    OmegaReportDownloadFormat,
    OmegaReportFilter,
    OmegaReportItemLabel,
    OmegaReportLink,
    SummaryTileVm,
} from '../../types';
import { OmegaReportCache } from './omega-report.cache';
import { STATE_QS_KEY } from './omega-report.constants';
import { createFilterRecord } from './omega-report.helpers';
import {
    ActionDetail,
    ActionMap,
    AlertingListeningElement,
    ProvidedFilters,
    ProvidedLocalFilters,
    StateObject,
} from './omega-report.types';

export const OmegaReportTagName = 'omega-report';

@Injectable()
@customElement(OmegaReportTagName)
export class OmegaReport<T> extends AlertingListeningElement {
    @property({
        type: Object,
    })
    public actions: ActionMap = {};

    @property({
        type: Boolean,
        reflect: true,
    })
    public autostart = false;

    @property({
        type: String,
    })
    public callToAction?: string;

    @property({
        type: Array,
    })
    public downloadOptions?: OmegaReportDownloadFormat[];

    @property({
        type: Function,
    })
    public downloadFunction?: (e: CustomEvent) => void;

    @property({
        type: Function,
    })
    public printFunction?: Action;

    @property({
        type: Function,
    })
    public detailFunction?: (record: FdlRecord<T>, close: () => void) => TemplateResult;

    @property({
        type: Boolean,
        reflect: true,
    })
    public displayToggleAll = false;

    @property({
        type: Boolean,
        reflect: true,
    })
    public flyout = false;

    @property({
        type: Boolean,
    })
    public filteringDisabled = false;

    @property()
    public filters?: ProvidedFilters<T>;

    @property()
    public localFilters?: ProvidedLocalFilters<T>;

    @property({
        type: Object,
    })
    public recordset!: Recordset<T, T>;

    @property({
        type: Array,
    })
    public summary?: RecordsetSummaryModel[];

    @property({
        type: Object,
    })
    public selectedSummaryTile?: SummaryTileVm;

    @property({
        type: Array,
    })
    public options: string[] = [];

    @property({ type: Array })
    public paginationOptions: number[] = [10, 25, 50, 100];

    @property({
        type: Object,
    })
    public params?: Record<string, unknown>;

    @property({
        type: Array,
    })
    public records?: FdlRecord[];

    @property({
        type: Array,
    })
    public reportActions?: OmegaReportAction<never>[];

    @property({
        type: Array,
    })
    public reportLinks?: OmegaReportLink[];

    @property({
        type: Number,
    })
    public rowsPerPage = 10;

    @property({
        type: String,
    })
    public title = '';

    @property({
        type: String,
    })
    public description = '';

    @property({
        type: Object,
    })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public reportInformation?: TemplateResult<any>;

    @property({
        type: String,
    })
    public subTitle?: string;

    @property({
        type: Object,
    })
    public itemLabel: OmegaReportItemLabel = {
        singular: 'item',
        plural: 'items',
    };

    @property({
        type: Array,
    })
    public tableFilters?: LocalFilterResult<T>[];

    @property({
        type: Boolean,
    })
    public tableLoading = false;

    @property({
        type: Boolean,
    })
    public loading = true;

    @property({
        type: Boolean,
    })
    public hideDetailColumn?: boolean;

    @property({
        type: Boolean,
        attribute: false,
    })
    public indicateFiltering = true;

    /**
     * Indicates whether the report should persist its state to
     * the in-memory cache and use it for hydration upon subsequent navigation.
     */
    @property({
        type: Boolean,
    })
    public persistState = false;

    @state()
    private filterRecord?: FdlRecord<T>;

    @state()
    private reportHasRun = false;

    @state()
    private recordsObtained = false;

    @property({
        type: Array,
    })
    public columns: OmegaColumnDefinition<T>[] = [];

    private parameters?: T;

    private filterTree?: LocalFilterResult<T> | [];

    @InjectProperty()
    private declare readonly cache: OmegaReportCache;

    @InjectProperty()
    private declare readonly window: WindowService;

    static get meta() {
        return {
            docUrl: 'https://banno.github.io/treasury-management/?path=/docs/components-report--basic',
        };
    }

    private get stateId() {
        const params = this.window.searchParams;
        return params.get(STATE_QS_KEY);
    }

    private get isCustomReport() {
        return false;
    }

    private get shouldRenderNoResultsMessage() {
        return this.reportHasRun && !this.recordsObtained && !this.tableLoading;
    }

    public get updating() {
        return this.recordset.updating;
    }

    public disconnectedCallback(): void {
        // eslint-disable-next-line wc/guard-super-call
        super.disconnectedCallback();

        // clean up listeners in case recordset has been persisted
        this.recordset.removeListeners();
    }

    private get noResultsMessage() {
        return `No ${this.title} found.`;
    }

    public async firstUpdated() {
        if (!this.recordset) {
            throw new Error(`Cannot create <${OmegaReportTagName}>. No recordset provided.`);
        }

        this.loading = true;
        this.listenToRecordsetEvents();
        await this.tryConstructFilters();

        const persistedState = this.getPersistedState();
        if (this.persistState && persistedState) {
            const { recordset, filterRecord, filters } = persistedState;
            this.initFromPersisted(
                recordset,
                filterRecord as FdlRecord<T>,
                filters as OmegaReportFilter<T>[]
            );
        } else if (this.autostart || !this.filters) {
            this.fetchRecords();
        }

        this.loading = false;
        this.isRunReportDisabled();
        this.requestUpdate();
    }

    private async tryConstructFilters() {
        try {
            this.filterRecord = await this.constructFilters();
        } catch (e) {
            const message = e instanceof Error ? e.message : 'An error occurred.';
            this.alert = {
                ...this.alert,
                visible: true,
                type: 'error',
                message,
            };

            console.error(e);
        }
    }

    private async constructFilters() {
        if (!this.filters) {
            return undefined;
        }

        const filters = await coerceToAsyncValue(this.filters);
        // re-assign array to filters property since other methods need this format
        this.filters = filters;

        const filterRecord = createFilterRecord(filters);
        this.listenTo(filterRecord, 'change', () => {
            this.isRunReportDisabled();
        });

        return filterRecord;
    }

    private listenToRecordsetEvents() {
        this.listenToRecordset(this.recordset, RecordsetEvent.Updated, () =>
            this.onRecordsetUpdateOrChange()
        );
        this.listenToRecordset(this.recordset, RecordsetEvent.Changed, () =>
            this.onRecordsetUpdateOrChange()
        );
        this.listenToRecordset(this.recordset, RecordsetEvent.PageChanged, () => {
            this.recordsObtained = this.recordset.totalCount >= 1;
        });
        this.listenToRecordset(this.recordset, RecordsetEvent.Loading, ({ detail }) => {
            const { loading } = detail;
            this.tableLoading = loading;
        });
    }

    private onRecordsetUpdateOrChange() {
        this.requestUpdate();
        this.areReportActionsDisabled();
    }

    /**
     * Persists the current record and filters to the report cache
     * to be reconstituted after future navigations.
     */
    private cacheState() {
        const { history, location } = this.window;
        const id = this.cache.store(
            this.recordset,
            this.filterRecord,
            this.filters as OmegaReportFilter<T>[]
        );
        const stateObject: StateObject = {
            datastoreKey: id,
        };

        history.replaceState(stateObject, '', `${location.pathname}?${STATE_QS_KEY}=${id}`);
    }

    private getPersistedState() {
        const { stateId } = this;

        if (!stateId) {
            return undefined;
        }

        return this.cache.retrieve(stateId);
    }

    public updated(changedProps: Map<keyof OmegaReport<T>, OmegaReport<T>[keyof OmegaReport<T>]>) {
        if (changedProps.has('localFilters')) {
            this.recordset.filter = buildFilter(this.localFilters);
            this.recordset.requestSoftUpdate();
        }
        if (changedProps.has('detailFunction') && exists(this.detailFunction)) {
            this.columns = [
                ...this.columns,
                {
                    type: OmegaColumnType.Detail,
                    label: '',
                    'display-toggle-all': this.displayToggleAll,
                },
            ];
        }
    }

    private async fetchRecords() {
        this.setReportParams();
        this.reportHasRun = true;
        await this.recordset.requestHardUpdate();
        this.toggleFilters();
        this.requestUpdate();

        if (this.persistState) {
            this.cacheState();
        }
    }

    private initFromPersisted(
        persistedRecordset: Recordset<T, T>,
        filterRecord?: FdlRecord<T>,
        filters?: OmegaReportFilter<T>[]
    ) {
        this.recordset.copyFrom(persistedRecordset, undefined, true);
        this.filterRecord = filterRecord;
        this.filters = filters;
        this.recordsObtained = true;

        if (this.filterRecord) {
            this.listenTo(this.filterRecord, 'change', () => {
                this.isRunReportDisabled();
            });
        }

        this.setReportParams();
        this.toggleFilters();
        this.requestUpdate();
    }

    private setReportParams() {
        if (!exists(this.filterRecord)) {
            return;
        }
        const params = { ...this.filterRecord.values };
        this.parameters = params;
        this.recordset.parameters = params;
        this.reportHasRun = true;
    }

    public forceFetch() {
        this.recordset.isFetchNeeded = true;
        this.runReport();
    }

    private createFilters(): LocalFilterResult<T> | [] {
        const { filterRecord } = this;
        if (!filterRecord || !(filterRecord.values instanceof Object)) {
            return [];
        }

        const keys = Object.keys(filterRecord.values) as (keyof T)[];
        const tuples = keys.map(key => {
            const localFilter: LocalFilter<T> = [
                'equals',
                key,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (filterRecord.getField(key) as any)?.toString(),
            ];

            return localFilter;
        });

        const filterTree = ['and', ...tuples] as LocalFilterResult<T>;
        filterTree.searchText = '';
        return filterTree;
    }

    private areReportActionsDisabled() {
        if (!this.reportActions) {
            return true;
        }

        return this.reportActions.some(action => action.isDisabled());
    }

    private runReport() {
        this.filterTree = this.createFilters();
        this.summary = undefined;
        this.dispatchEvent(new CustomEvent('search'));
        this.fetchRecords();
    }

    private runFilters(record: FdlRecord<T>) {
        this.filterRecord = record;
        this.fetchRecords();
    }

    private resetFilters() {
        this.filterRecord?.reset();
    }

    private isRunReportDisabled() {
        this.filteringDisabled = exists(this.filterRecord)
            ? !this.filterRecord.hasRequiredValues() || this.filterRecord.hasErrors()
            : true;
    }

    private toggleFilters() {
        if (this.recordsObtained) {
            setTimeout(() => {
                if (!this.shadowRoot) {
                    throw new Error(
                        'Could not toggle filters. No shadow root in omega-report available.'
                    );
                }

                const tableFiltersElem =
                    this.shadowRoot.querySelector<TableFilters>('table-filters');
                tableFiltersElem?.toggleFilters();
            });
        }
    }

    private handleActions({ action, record, rowIndex }: ActionDetail) {
        this.actions[action](record, rowIndex);
    }

    private handleCallToAction() {
        this.dispatchEvent(new CustomEvent('callToAction'));
    }

    private handleDownload(e: CustomEvent) {
        this.dispatchEvent(
            new CustomEvent('reportDownload', {
                bubbles: true,
                composed: true,
                detail: {
                    filter: this.filterRecord?.values,
                    type: e.detail.downloadType,
                },
            })
        );
    }

    private handleSave(e: CustomEvent) {
        this.dispatchEvent(
            new CustomEvent('reportSave', {
                bubbles: true,
                composed: true,
                detail: {
                    filter: this.filterRecord?.values,
                    name: e.detail.reportName,
                },
            })
        );
    }

    private handlePrint(e: CustomEvent) {
        if (this.printFunction) {
            this.printFunction();
            return;
        }

        // TODO: remove any instances of BO reports calling this function with the new
        // TODO: printNode() utility via printFunction()
        const tableFilters = this.shadowRoot?.querySelector<TableFilters>('table-filters');
        if (tableFilters?.filtersVisible) {
            tableFilters.toggleFilters();
        }

        this.dispatchEvent(
            new CustomEvent('pagePrint', {
                bubbles: true,
                composed: true,
                detail: e,
            })
        );

        this.window.print();
    }

    private renderHeaderBar() {
        if (!this.flyout) {
            return html`<omega-download-bar
                id="report-download-bar"
                .pageTitle=${this.subTitle}
                .reportTitle=${this.title}
                .actions=${this.options}
                .description=${this.description}
                .message=${this.reportInformation}
                tooltipDirection="bottom"
                .downloadOptions=${this.downloadOptions}
                ?customReport=${this.isCustomReport}
                @save=${this.handleSave}
                @download=${this.handleDownload}
                @print=${this.handlePrint}
                .disableSave=${!this.reportHasRun && !this.loading}
                .disableExportOptions=${!this.loading && this.recordset.totalCount === 0}
                .disablePrint=${!this.loading && this.recordset.totalCount === 0}
            ></omega-download-bar>`;
        }

        return html`<omega-action-bar
            .title=${this.title}
            .options=${this.options}
            .downloadOptions=${this.downloadOptions}
            .disableAction=${this.loading}
            .disableOptions=${this.loading || this.recordset.totalCount === 0}
            .callToAction=${this.callToAction}
            .actions=${this.reportLinks}
            .message=${this.reportInformation}
            tooltipDirection="bottom"
            @callToActionClicked=${this.handleCallToAction}
            @print=${this.handlePrint}
            @download=${this.handleDownload}
        ></omega-action-bar>`;
    }

    private renderFlyoutFilters() {
        if (!this.flyout || !Array.isArray(this.filters) || !this.filterRecord || this.loading) {
            return nothing;
        }

        const title = `Search ${this.title}`;
        const filterFields = this.filters.map(filter => {
            const { required, label, inline, fieldType, value } = filter;
            if (filter.multiple) {
                const { fields, items } = filter;

                return html`<omega-field
                    class="flyout-filter"
                    .record=${this.filterRecord}
                    .value=${value}
                    .fields=${fields}
                    .fieldType=${fieldType}
                    .required=${!!required}
                    .label=${label}
                    .inline=${!!inline}
                    .visible=${fieldType.visible(this.filterRecord)}
                    .items=${items}
                ></omega-field>`;
            }

            const { field } = filter;
            return html`<omega-field
                class="flyout-filter"
                .record=${this.filterRecord}
                .field=${field as string}
                .required=${!!required}
                .label=${label}
                .inline=${!!inline}
                .visible=${fieldType.visible(this.filterRecord)}
            ></omega-field>`;
        });

        return html`<omega-flyout .title=${title} .open=${!this.recordsObtained}>
            <div class="flyout-filter-fields">${filterFields}</div>
            <div class="flyout-actions">
                <omega-button
                    type="primary"
                    @click=${this.runReport}
                    ?disabled=${this.filteringDisabled}
                    >Search</omega-button
                >
                <omega-button @click=${this.resetFilters}>Reset</omega-button>
            </div>
        </omega-flyout>`;
    }

    private renderTableFilters() {
        if (this.flyout || !this.filters || this.loading) return nothing;

        return html`<table-filters
            .filters=${this.filters}
            .filterRecord=${this.filterRecord}
            .filteringDisabled=${this.filteringDisabled}
            @runReport=${(e: CustomEvent) => this.runFilters(e.detail.filterRecord)}
            @resetFilters=${() => {
                this.dispatchEvent(new CustomEvent('resetFilter'));
                this.resetFilters();
            }}
        ></table-filters>`;
    }

    private renderSummaryTiles() {
        if (!this.recordsObtained || this.loading) return nothing;
        if (!this.summary) return nothing;
        return html`
            <omega-summary-tile-select-bar
                .tiles=${this.summary}
                .selectedTile=${this.selectedSummaryTile}
                @tileClicked=${(e: CustomEvent<SummaryTileVm>) => {
                    this.fetchRecords();
                    this.dispatchEvent(
                        new CustomEvent<SummaryTileVm>('summaryTileClicked', {
                            detail: e.detail,
                        })
                    );
                }}
            ></omega-summary-tile-select-bar>
        `;
    }

    private renderButtons() {
        if (!this.reportActions || !this.recordsObtained) {
            return nothing;
        }

        const visibleReportActions = this.reportActions.filter(action =>
            action.visibleWhen ? action.visibleWhen() : true
        );

        const renderButtonBar = (
            alignment: string,
            buttons: OmegaReportAction<never>[] | unknown
        ) =>
            html`<omega-button-bar alignment=${alignment} position="relative">
                ${buttons}
            </omega-button-bar>`;

        const renderButton = (action: OmegaReportAction<never>) =>
            html`<omega-button
                type=${action.type as string}
                ?disabled=${action?.isDisabled() || false}
                @click=${action.action}
                >${action.label}</omega-button
            >`;

        const renderLeftSideButtons = () => {
            const leftSideButtons = visibleReportActions
                .filter(action => action?.alignment !== 'Right')
                .map(action => renderButton(action));

            if (leftSideButtons.length === 0) {
                return nothing;
            }

            return renderButtonBar('left', leftSideButtons);
        };

        const renderRightSideButtons = () => {
            const rightSideButtons = visibleReportActions
                .filter(action => action?.alignment === 'Right')
                .map(action => renderButton(action));

            if (rightSideButtons.length === 0) {
                return nothing;
            }

            return renderButtonBar('right', rightSideButtons);
        };

        return html`<div class="button-bars-container">
            ${renderLeftSideButtons()} ${renderRightSideButtons()}
        </div>`;
    }

    private renderLoader() {
        if (!this.loading) return nothing;
        return html`<omega-progress card class="large-loader"></omega-progress>`;
    }

    private renderEmptyState() {
        const { reportHasRun, loading, shouldRenderNoResultsMessage } = this;
        const reportNotStarted = !reportHasRun && !loading;

        if (reportNotStarted || shouldRenderNoResultsMessage) {
            if (this.flyout) {
                const noResultsElem = shouldRenderNoResultsMessage
                    ? html`<p>${this.noResultsMessage}</p>`
                    : nothing;

                return html`<div class="empty-state">
                    <h2>Enter Filter/Search Criteria</h2>
                    ${noResultsElem}
                </div>`;
            }

            return html`<div class=${classMap({ 'empty-state': true, darken: !this.flyout })}>
                <span>Please select filter preferences and click <b>Run Report</b></span>
            </div>`;
        }

        return nothing;
    }

    private renderTableData() {
        if (!this.reportHasRun || this.shouldRenderNoResultsMessage) {
            return nothing;
        }

        return html`
            <omega-table
                .itemLabel=${this.itemLabel}
                .autostart=${this.autostart}
                .recordset=${this.recordset}
                .rowsPerPage=${this.rowsPerPage}
                .rowsPerPageOptions=${this.paginationOptions}
                .columnDefinitions=${this.columns}
                .detailRowTemplateFunction=${this.detailFunction}
                .params=${this.parameters}
                .filters=${this.localFilters}
                .loading=${this.tableLoading}
                .hideDetailColumn=${this.hideDetailColumn}
                .indicateFiltering=${this.indicateFiltering}
                @action=${(e: CustomEvent<ActionDetail>) => this.handleActions(e.detail)}
                @error=${(e: CustomEvent) => {
                    this.alert = { ...e.detail.error, type: 'error', visible: true };
                }}
            >
            </omega-table>
        `;
    }

    public render() {
        return html`
            <slot></slot>
            <div class="report-column">${this.renderFlyoutFilters()}</div>
            <div class="table">
                ${this.renderHeaderBar()}
                <slot name="below-header"></slot>
                ${this.renderTableFilters()}
                <slot name="above-table"></slot>
                ${this.renderSummaryTiles()} ${this.renderLoader()} ${this.renderEmptyState()}
                ${this.renderTableData()} ${this.renderButtons()}
            </div>
        `;
    }

    static get styles() {
        return css`
            :host {
                display: flex;
                position: relative;
                height: 100%;
                align-items: stretch;
                box-sizing: border-box;
                border: 1px solid #eeededfd;
                box-shadow: 0px 0px 2px 2px rgba(150, 150, 150, 0.75);
                --omega-table-overflow: auto;
                --omega-table-flex-grow: 1;
            }
            table-filters {
                z-index: 2;
            }
            .report-column {
                position: relative;
                height: 100%;
            }
            .table {
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                position: relative;
                overflow: hidden;
                background-color: var(--omega-white);
            }
            .flyout-actions {
                position: sticky;
                bottom: 0;
                display: flex;
                width: 100%;
                justify-content: space-between;
                background: var(--omega-secondary-lighten-400);
                border-top: 1px solid var(--omega-secondary-lighten-300);
                min-height: 52px;
            }
            .flyout-filter-fields {
                flex: 1;
                overflow: scroll;
                padding: 10px;
                box-sizing: border-box;
            }
            .flyout-filter {
                padding: 0 0 15px 0;
                width: 330px;
            }
            .empty-state {
                display: flex;
                flex-direction: column;
                height: 100vh;
                align-items: center;
                justify-content: center;
                text-align: center;
            }
            .empty-state.darken {
                background-color: #ecf2f6;
            }
            .empty-state span {
                font-size: var(--omega-h3);
            }
            .button-bars-container {
                display: flex;
                justify-content: space-between;
            }
            @media print {
                omega-table {
                    transform: scale(var(--omega-report-table-print-scale, 0.7));
                    page-break-inside: avoid;
                    position: relative;
                    transform-origin: 0 0;
                }
                .report-column {
                    display: none;
                }
                omega-table th,
                thead td {
                    top: 0;
                }
            }
        `;
    }
}
